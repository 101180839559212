<template>
  <div>
    <transition name="fade">
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <div class="d-flex">
          <feather-icon
            icon="ArrowLeftIcon"
            size="24"
            class="align-middle text-primary mr-2 mb-2 back"
            @click="$router.go(-1)"
          />
          <h3>Detail Leader</h3>
        </div>
        <b-row>
          <b-col
            cols="12"
            xl="9"
            lg="8"
            md="7"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="21"
                  xl="6"
                  class="d-flex justify-content-start"
                >
                  <b-avatar
                    :src="userData.fotoProfil"
                    :text="avatarText(userData.nama)"
                    variant="light-primary"
                    size="104px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ userData.nama }}
                      </h4>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <table class="mt-2 mt-xl-0 w-100">
                    <tr
                      v-if="permission.mitraUpdate"
                    >
                      <th>
                        <feather-icon
                          icon="PhoneIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Nomor HP</span>
                      </th>
                      <td>
                        {{ userData.no_hp }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="UserIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Email</span>
                      </th>
                      <td>
                        {{ userData.email }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="ClockIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Waktu Daftar</span>
                      </th>
                      <td>
                        {{ getDate(userData.waktu_daftar) }}
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="userData.status_aktif === 3">
              <h3>
                <feather-icon
                  icon="AlertCircleIcon"
                  class="text-danger"
                  size="28"
                />
                Data Mitra Ditolak
              </h3>
              <div v-if="userData.alasan_ditolak">
                <p>Data mitra ini ditolak karena alasan : </p>
                <h5 class="font-weight-bolder text-warning">
                  {{ userData.alasan_ditolak }}
                </h5>
                <small>Update terakhir : {{ getDate(userData.terakhir_diedit) }}</small>
              </div>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="5"
            xl="3"
            lg="4"
          >
            <b-card>
              <b-button
                v-if="permission.leaderDownload"
                variant="success"
                block
                @click="exportReporting(userData.nama)"
              >
                Download Excel
              </b-button>
              <b-button
                variant="danger"
                block
              >
                Hapus Leader
              </b-button>
              <hr>
              <b-button
                variant="outline-warning"
                block
              >
                Blokir Leader
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <span class="mb-2">Bulan</span>
                <v-select
                  v-model="selectedBulan"
                  placeholder="Pilih tanggal"
                  :options="options"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1 mb-2"
                  @close="applyFilterReporting()"
                />
              </b-col>
            </b-row>
            <b-table
              responsive
              striped
              hover
              :items="items"
              :fields="fields"
              class="position-relative"
            />
            <div class="text-center">
              <b-button
                class="btn btn-success"
                @click="loadMore()"
              >
                <feather-icon icon="ChevronDownIcon" />
                Lihat lebih banyak
              </b-button>
            </div>
          </div>
        </b-card>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import 'vue-img-viewr/styles/index.css'
import vSelect from 'vue-select'
/* eslint-disable no-unused-vars */
import axios from 'axios'
import fileDownload from 'js-file-download'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import permission from '@/permission'
import regionJson from '@/db/region.json'
import { db } from '@/firebase'

/* eslint-disable arrow-body-style */
/* eslint-disable radix */
export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BSpinner,
    BTable,

    vSelect,
  },
  props: ['id'],
  data() {
    return {
      saldo: 0,
      permission: permission.access,
      loading: true,
      userData: '',
      getPesanan: [],
      getPayment: [],
      region: regionJson,

      selectedBulan: '',
      options: [],
      btnLoadMore: true,
      perPage: 5,
      perPageReport: [5, 10, 15],

      fields: [
        {
          key: 'waktu_order',
          label: 'Tanggal',
          sortable: true,
        },
        {
          key: 'nomor_order',
          label: 'Nomor Order',
          sortable: true,
        },
        {
          key: 'nama_customer',
          label: 'Nama Customer',
          sortable: true,
        },
        {
          key: 'nama_mitra',
          label: 'Nama Mitra',
          sortable: true,
        },
        {
          key: 'no_hp_mitra',
          label: 'No Hp Mitra',
          sortable: true,
        },
        {
          key: 'subtotal',
          label: 'Total',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status Pesanan',
          sortable: true,
        },
        {
          key: 'komisi_leader',
          label: 'Komisi Leader',
          sortable: true,
        },
        {
          key: 'komisi_pusat',
          label: 'Komisi Pusat',
          sortable: true,
        },
        {
          key: 'kecamatan',
          label: 'Kecamatan',
          sortable: true,
        },
      ],
      items: [],
      allItems: [],
      loadMoreBtn: true,
    }
  },
  created() {
    this.getLeader()
    this.getBireporting()
  },
  methods: {
    getDate(unix) {
      const timestamp = unix
      const dateObject = new Date(timestamp)

      return dateObject.toLocaleString('id-ID', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric',
      })
    },
    getBireporting() {
      /* eslint-disable newline-per-chained-call */

      const limit = this.perPage <= 5 ? 5 : this.perPage
      this.loadMoreBtn = true
      db.collection('mecarehub').doc(this.id).collection('bireporting').limit(limit).get().then(querySnapshot => {
        const arr = []
        querySnapshot.forEach(doc => {
          arr.push({
            id: doc.id,
            bulan_tahun: doc.data().bulan_tahun,
            waktu_order: doc.data().waktu_order,
            nomor_order: doc.data().nomor_order,
            nama_customer: doc.data().nama_customer,
            nama_mitra: doc.data().nama_mitra,
            no_hp_mitra: doc.data().no_hp_mitra,
            subtotal: doc.data().subtotal,
            status: this.statusOrder(doc.data().status).display,
            komisi_leader: doc.data().komisi_leader,
            komisi_pusat: doc.data().komisi_pusat,
            kecamatan: doc.data().kecamatan,
          })
          if (!this.options.includes(doc.data().bulan_tahun)) {
            this.options.push(doc.data().bulan_tahun)
          }
        })
        this.items = [...new Set(arr)]
        this.allItems = [...new Set(arr)]
      })
    },
    loadMore() {
      if (this.allItems.length === this.perPage) {
        this.perPage += 10
        this.getBireporting()
      } else {
        this.loadMoreBtn = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data sudah ditampilkan semua',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
    applyFilterReporting() {
      this.allItems.filter(el => {
        return el.bulan_tahun === this.selectedBulan
      })
    },
    getLeader() {
      db.collection('mecarehub').doc(this.id).get().then(async snapshot => {
        this.userData = snapshot.data()
        this.loading = false
      })
    },
    statusOrder(status) {
      if (status === 0) return { variant: 'light-danger', display: 'Membuat proses' }
      if (status === 1) return { variant: 'light-primary', display: 'Mencari Mitra' }
      if (status === 2) return { variant: 'light-primary', display: 'Terjadwal' }
      if (status === 3) return { variant: 'light-primary', display: 'Mitra dalam perjalanan' }
      if (status === 4) return { variant: 'light-primary', display: 'Mitra sudah sampai' }
      if (status === 5) return { variant: 'light-primary', display: 'Sedang mengerjakan' }
      if (status === 6) return { variant: 'light-primary', display: 'Menunggu pembayaran' }
      if (status === 7) return { variant: 'light-primary', display: 'Selesai' }
      if (status === 8) return { variant: 'danger', display: 'Dibatalkan oleh customer' }
      if (status === 9) return { variant: 'danger', display: 'Dibatalkan oleh sistem' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },
    exportReporting(nama) {
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/downloadReportLeader',
        method: 'POST',
        data: {
          leader_id: this.id,
          bulan_tahun: this.selectedBulan,
        },
      }
      this.$axios(options).then(res => {
        fileDownload(res.data, `report leader ${nama}.csv`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil download bireporting',
            icon: 'BellIcon',
            variant: 'success',
            position: 'bottom',
          },
        })
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
  .badge-mitra{
    margin: 2px;
  }

  .dokumen-card img{
    object-fit: cover;
  }

  .dropdown-blokir:hover{
    background-color: #ff9f43;
  }

  .dropdown-item:hover, .dropdown-item:focus{
    color: #fff;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
